<template lang="pug">
    ArticleWrapper
        h1
          v-icon.mr-4(large color="black") mdi-television-play
          | Create Your Own TV Show Lesson

        pre lesson {{lesson}}
        pre questions {{questions}}
        CreateLessonMeta(color="blue lighten-5", @change="initLesson")
        CreateQuestions.my-5(type="warmup", color="yellow lighten-5" @change="initLesson")
        CreateKeyExpressions.my-5(color="grey lighten-5", @change="initLesson")
        CreateQuestions.my-5(type="checkUnderstanding" color="blue lighten-5", allowAnswers, @change="initLesson")
        CreateQuestions.my-5(type="discussion" color="pink lighten-5", @change="initLesson")
        CreatorPreview(:showPreview="true")

</template>

<script>
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import CreateLessonMeta from "@/components/LessonCreator/CreateLessonMeta.vue";
import CreateQuestions from "@/components/LessonCreator/CreateQuestions.vue";
import CreateKeyExpressions from "@/components/LessonCreator/CreateKeyExpressions.vue";
import CreatorPreview from "@/components/LessonCreator/CreatorPreview.vue";

import { sync } from "vuex-pathify";
export default {
  name: "LessonCreator",
  components: {
    CreateLessonMeta,
    CreateKeyExpressions,
    ArticleWrapper,
    CreateQuestions,
    CreatorPreview,
  },
  watch: {
    questions: {
      deep: true,
      handler() {
        this.initLesson();
      },
    },
  },
  created() {},
  computed: {
    ...sync({
      lesson: "Lessons/lesson",
      meta: "LessonCreator/meta",
      questions: "LessonCreator/questions",
      keyExpressions: "LessonCreator/keyExpressions",
    }),
  },
  methods: {
    initLesson() {
      const getQuestions = (key, withAnswers = false) =>
        this.questions && this.questions[key] && this.questions[key].length > 0
          ? this.questions[key]
              .map((q) => {
                return withAnswers
                  ? { question: q.question, answer: q.answer }
                  : q.question;
              })
              .filter(Boolean)
          : [];

      this.lesson = {
        id: "preview-lesson",
        show: this.meta.show,
        season: this.meta.season,
        episode: this.meta.episode,
        title: this.meta.title,
        warmup: getQuestions("warmup"),
        checkUnderstanding: getQuestions("checkUnderstanding", true),
        discussion: getQuestions("discussion"),
        keyExpressions: this.keyExpressions,
      };
    },
  },
};
</script>
