<template lang="pug">
    .create-questions
        CardTitleInset(:value="title", :color="color")
        v-card.px-5.py-2(flat :color="color", v-if="questions && questions[type]")
            v-row.question.my-6(v-for="(question, idx) in questions[type]", :key="question.id", align="center")
                v-col(cols="12", md="2").text-center
                    .oblique.display-2.text--disabled Q{{idx+1}}
                v-col(cols="12", md="10")
                    v-row(dense align="center")
                      v-col(cols="10")
                        v-text-field(outlined v-model="question.question" label="Write your own question" x-large hide-details placeholder="What do you think about...", background-color="white")
                      v-col( cols="2")
                        .buttons
                            v-tooltip(top v-if="allowAnswers")
                                    template(v-slot:activator="{ on, attrs }")
                                        v-btn(icon v-on="on", @click="questionHasAnswer(question) ? handleRemoveAnswerFromQuestion(question) : $set(question, 'answer', null)")
                                            v-icon {{question.answer ? 'mdi-alpha-a-circle-outline' : 'mdi-alpha-a-box'}}
                                    span (Optional) Add an answer that only the teacher sees
                            v-tooltip(top)
                                    template(v-slot:activator="{ on, attrs }")
                                        v-btn(icon color="error lighten-1" @click="handleDeleteQuestion(question)" v-on="on")
                                            v-icon mdi-trash-can-outline
                                    span Delete this question?
                    v-row(dense)
                      v-col(cols="10")
                        .answer.mt-3(v-if="questionHasAnswer(question)")
                            v-text-field.answer(outlined v-model="question.answer", background-color="white", placeholder="The answer is...", hide-details)
                        
            
            .buttons
                v-btn(color="primary" depressed x-large :block="$vuetify.breakpoint.smAndDown", @click="handleAddQuestion").mr-md-2
                    v-icon.mr-2 mdi-plus-circle
                    | Add another

</template>

<script>
import { nanoid } from "nanoid";
import { sync } from "vuex-pathify";
import CardTitleInset from "@/components/CardTitleInset.vue";
export default {
  name: "CreateQuestions",
  components: { CardTitleInset },
  props: {
    type: { type: String, required: true },
    color: { type: String, default: "grey lighten-5" },
    allowAnswers: { type: Boolean, default: false },
  },
  data() {
    return {
      numQuestions: 1,
    };
  },
  computed: {
    ...sync({ questions: "LessonCreator/questions" }),
    title() {
      let res = "Create";
      if (!this.type) return res;
      else if (this.type === "warmup") {
        res = "Create Warm-Up Questions";
      } else if (this.type === "discussion") {
        res = "Create Discussion Questions";
      } else if (this.type === "checkUnderstanding") {
        res = "Create Questions to Check Understanding";
      }

      return res;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const newQuestion = {
        id: nanoid(),
      };
      if (
        !this.questions ||
        !this.questions[this.type] ||
        this.questions[this.type].length < 1
      )
        this.$set(this.questions, this.type, [newQuestion]);
    },
    questionHasAnswer(question) {
      return "answer" in question;
    },
    handleAddQuestion() {
      const newQuestion = {
        id: nanoid(),
      };

      this.questions[this.type].push(newQuestion);
    },
    handleAddAnswerToQuestion(questionNum) {
      try {
        if (
          !this.questions ||
          !this.questions[this.type] ||
          this.questions[this.type].length < 1
        )
          throw new Error("You'll need to type the question first.");
        const question = this.questions[this.type].find(
          (question) => question.id === questionNum
        );
        if (!question)
          throw new Error("You'll need to type the question first");
        console.log({ question });
        this.$set(question, "answer", {});
      } catch (err) {
        alert(err.message);
      }
    },
    handleRemoveAnswerFromQuestion(question) {
      this.$delete(question, "answer");
    },
    handleDeleteQuestion(question) {
      const newSet = this.questions[this.type].filter(
        (existingQuestion) => existingQuestion.id !== question.id
      );

      this.$set(this.questions, this.type, newSet);
    },
  },
};
</script>
