<template lang="pug">
    .create-lesson-meta.mt-6
      CardTitleInset(value="Lesson Information", :color="color")
      v-card(:color="color" flat)
        .pa-5
          v-row(dense)
            v-col(cols="12" md="2")
              v-subheader.px-0 Show Name
            v-col(cols="12" md="10")
              v-text-field(v-model="meta.show", label="Friends, Rick & Morty, etc", outlined, placeholder="Rick and Morty", background-color="white")
          v-row(dense)
            v-col(cols="12" md="2")
              v-subheader.px-0 Episode Title
            v-col(cols="12" md="10")
              v-text-field(v-model="meta.title", label="Wedding episode, Halloween Episode, etc", outlined, placeholder="Wedding Episode", background-color="white")
          v-row.season(dense)
            v-col(cols="4", md="2")
              v-subheader.px-0 Season #
            v-col(cols="8", md="10")
              input(v-model="meta.season", type="number", placeholder="?", min="1", max="50").outlined
          v-row.episode(dense)
            v-col(cols="4" md="2")
              v-subheader.px-0 Episode #
            v-col(cols="8", md="10")
              input(v-model="meta.episode", type="number", placeholder="?", min="1" max="99").outlined

</template>

<script>
import { sync } from "vuex-pathify";
import CardTitleInset from "@/components/CardTitleInset.vue";
export default {
  name: "Preview",
  components: { CardTitleInset },
  props: {
    color: { type: String, default: "grey lighten-5" },
  },
  data() {
    return {};
  },
  watch: {
    meta: {
      deep: true,
      handler() {
        this.$emit("change");
      },
    },
  },
  computed: {
    ...sync({
      lesson: "Lessons/lesson",
      meta: "LessonCreator/meta",
      questions: "LessonCreator/questions",
      showPreview: "LessonCreator/showPreview",
    }),
  },
  created() {},
  methods: {
    round5(x) {
      return Math.ceil(x / 5) * 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.outlined {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px white;
  text-align: center;
}
</style>
