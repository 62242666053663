<template lang="pug">
    .create-key-expressions
        CardTitleInset(value="Add Key Expressions", :color="color")
        v-card.px-5.py-2(flat :color="color", v-if="keyExpressions")
            v-row.key-expression.my-6(v-for="(itm, idx) in keyExpressions", :key="itm.id", align="start")
                v-col(cols="12" md="2").text-center
                    .oblique.display-2.text--disabled Nº{{idx+1}}
                v-col(cols="12", md="10")
                    v-text-field(outlined v-model="itm.expression", label="Expression from show", placeholder="A dime a dozen", x-large, background-color="white")
                    v-text-field(outlined v-model="itm.meaning", label="Simple meaning", placeholder="Very cheap and available", x-large,  background-color="white")
                    v-text-field(outlined v-model="itm.example", label="Example sentence", placeholder="These days Blu-ray players are a dime a dozen", x-large,  background-color="white")
                    
                    v-subheader.ml-0.pl-0 Expression Type(s)
                    .d-flex.flex-wrap
                        v-checkbox(v-model="itm.types", value="idiom", label="Idiom").mr-3
                        v-checkbox(v-model="itm.types", value="slang", label="Slang").mr-3
                        v-checkbox(v-model="itm.types", value="rude", label="Rude").mr-3
                        v-checkbox(v-model="itm.types", value="abbreviation", label="Abbreviation").mr-3
                        v-checkbox(v-model="itm.types", value="neutral", label="Neutral")
                v-col(cols="10" offset="1")
                    v-divider.my-4
            .buttons
                v-btn(color="primary" depressed x-large :block="$vuetify.breakpoint.smAndDown", @click="handleAddExpression").mr-md-2
                    v-icon.mr-2 mdi-plus-circle
                    | Add another


</template>

<script>
import { nanoid } from "nanoid";
import { sync } from "vuex-pathify";
import CardTitleInset from "@/components/CardTitleInset.vue";
export default {
  name: "CreateKeyExpressions",
  components: { CardTitleInset },
  props: {
    color: { type: String, default: "grey lighten-5" },
  },
  computed: {
    ...sync({ keyExpressions: "LessonCreator/keyExpressions" }),
  },
  methods: {
    handleAddExpression() {
      const newExpression = {
        id: nanoid(),
        expression: "",
        meaning: "",
        example: "",
        types: [],
      };

      this.keyExpressions.push(newExpression);
    },
  },
};
</script>
