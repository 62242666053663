<template lang="pug">
    .card-title-inset
        .text.d-inline-block.pt-2.px-3.font-weight-bold(:class="color", :style="{'border-top-left-radius': '5px', 'border-top-right-radius': '5px'}") {{value}}
</template>

<script>
export default {
  name: "CardTitleInset",
  props: {
    value: { type: String, required: true },
    color: { type: String, default: "grey lighten-3" },
  },
};
</script>
