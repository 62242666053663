<template lang="pug">
    .preview-wrapper.p-relative
      template(v-if="showPreview")
        v-bottom-sheet.preview(v-model="showPreview", hide-overlay, scrollable, height="50vh")
          v-card(flat :color="color").overscroll
            .fixed-to-top
              v-btn(block color="warning" depressed tile @click="showPreview=false" x-large)
                v-icon.mr-2 mdi-close
                | Close Preview
            Title
            
            v-container
              v-row.flex-column-reverse.flex-md-row
                v-col(cols="12")
                    Summary(v-if="lesson && lesson.summary")
                v-col(cols="12" md="7" lg="8")
                    GettingStarted(:lesson="lesson")
                v-col(cols="12" md="5" lg="4")
                    NavDrawer
              
              
              .sections(v-if="lesson")
                WarmUp(subtitle="Warm up by asking your students to consider some open-ended general questions:" :time="round5(lesson.warmup.length * 1) + ' ~ ' + round5(lesson.warmup.length * 4) + ' minutes'")

                KeyExpressions(v-if="lesson.keyExpressions", :subtitle="`Review these ${lesson.keyExpressions.length} key expressions from the transcript:`", :time="'Approx ' + round5(lesson.keyExpressions.length) + ' ~ ' +  round5(lesson.keyExpressions.length * 2) + ' minutes'", preview)

                CheckUnderstanding(v-if="lesson.checkUnderstanding", subtitle="Let's make sure your students watched the episode and understood what was going on." :time="round5(lesson.checkUnderstanding.length * 2) + ' ~ ' + round5(lesson.checkUnderstanding.length * 3) + ' minutes'")
                    
                Discussion(subtitle="Lead students in an open-ended discussion of the scenes, themes, plot and characters in the episode" :time="round5(lesson.discussion.length * 4) + ' ~ ' + round5(lesson.discussion.length * 7) + ' minutes'")
            
      template(v-else)
        .p-fixed
          v-card(flat)
            v-btn(block depressed x-large, color="accent" @click="showPreview = true").font-weight-bold Preview this lesson
              v-icon.ml-2 mdi-eye

</template>

<script>
import { sync } from "vuex-pathify";

import NavDrawer from "@/components/Lesson/NavDrawer.vue";
import Summary from "@/components/Lesson/Sections/Summary.vue";
import GettingStarted from "@/components/Lesson/Sections/GettingStarted.vue";

import Title from "@/components/Lesson/Sections/Title.vue";

import WarmUp from "@/components/Lesson/Sections/WarmUp.vue";
import KeyExpressions from "@/components/Lesson/Sections/KeyExpressions/KeyExpressions.vue";
import CheckUnderstanding from "@/components/Lesson/Sections/CheckUnderstanding.vue";
import Discussion from "@/components/Lesson/Sections/Discussion.vue";

export default {
  name: "Preview",
  components: {
    NavDrawer,
    Summary,
    GettingStarted,
    Title,
    WarmUp,
    KeyExpressions,
    CheckUnderstanding,
    Discussion,
  },
  props: {
    color: { type: String, default: "grey lighten-5" },
  },
  data() {
    return {};
  },
  computed: {
    ...sync({
      isTeacher: "Lessons/isTeacher",
      lesson: "Lessons/lesson",
      questions: "LessonCreator/questions",
      showPreview: "LessonCreator/showPreview",
    }),
  },
  created() {
    this.isTeacher = true;
  },
  methods: {
    round5(x) {
      return Math.ceil(x / 5) * 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.fixed-to-top {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}
.overscroll {
  overscroll-behavior: contain;
  overflow-y: auto;
}
</style>
